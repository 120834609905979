import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import SectionWithImg from '../components/sectionWithImg'
import northrop from '../images/northrop.png'
import hud from '../images/hud.png';
import ssa from '../images/ssa.png';
import '../styles/about.css'

const About = ({path, data}) => (
  <Layout path={path}>
    <SEO title="About Us" />
    <SectionWithImg
      title='Why choose us?'
      subtitle='More about us'
      image={data.whyChooseUs}
      imageAlt='PriorityQ hard at work serving its clients.'
      titleLeft
    >
      <p className='flex-col-fix'>
        The PriorityQ team has extensive experience in the Information Technology Industry.
        We strive to provide a frictionless experience for our clients through our technical expertise and extensive industry experience.
        Our agile development process ensures transparency, quick iteration, and timely execution for our clients.
        PriorityQ specializes in Web / Mobile Application Development, Geospatial Solutions, Business Intelligence and Cloud Architecture.
      </p>
    </SectionWithImg>
    <div className='section-dark full-width-background'>
      <div className='clients-section'>
        <h6>Clients</h6>
        <h1>Our Awesome Clients</h1>
        <p>
          We have done fantastic work with the industry's biggest clients gaining immense technical experience and delivering outstanding results.
          Our clients appreciate our integrity, professionalism and commitment to delivering results in record time without sacrificing quality.
          </p>
        <div className='clients-section-row flex-row'>
          <div className='client-image'>
            <div className='flex-center'>
              <img src={ssa} alt="SSA's Logo" className='client-img-circle' />
            </div>
          </div>
          <div className='client-image'>
            <div className='flex-center'>
              <img src={northrop} alt="Northrop Grumman's Logo" />
            </div>
          </div>
          <div className='client-image'>
            <div className='flex-center'>
              <img src={hud} alt="HUD's Logo" className='client-img-circle' />
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default About;

export const query = graphql`
  query {
    whyChooseUs: file(relativePath: { eq: "whyChooseUs.png" }) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
