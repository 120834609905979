import React from 'react'
import PropTypes from 'prop-types'
import Img from "gatsby-image/withIEPolyfill"
import './sectionWithImg.css'

class SectionWithImg extends React.Component {
  static propTypes = {
    subtitle: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    image: PropTypes.any.isRequired,
    imageAlt: PropTypes.string.isRequired,
    imageRight: PropTypes.bool,
    titleLeft: PropTypes.bool,
    dark: PropTypes.bool
  }

  render() {
    console.log(this.props)
    const titleClass = this.props.imageRight || this.props.titleLeft ?
      'section-component-title section-component-title-right' : 'section-component-title';
    const content = (
      <div className='section-component'>
        <div className='flex-center'>
            <div className={titleClass}>
              <h6>{ this.props.subtitle }</h6>
              <h1 className="section-with-img-title">{ this.props.title }</h1>
            </div>
            { this.props.children }
        </div>
      </div>
    )
    const image = (
      <div className='section-component section-component-desktop'>
        <div className='flex-center'>
          <Img
            style={{width: "100%"}}
            fluid={this.props.image.childImageSharp.fluid}
            alt={this.props.imageAlt}
          />
        </div>
      </div>
    )

    const mobileContent = (
      <div className='section-component section-component-mobile'>
        <div className='flex-center'>
          <div>
            <h1>{ this.props.title }</h1>
            <Img
              style={{width: "100%"}}
              fluid={this.props.image.childImageSharp.fluid}
              alt={this.props.imageAlt}
            />
          </div>
        </div>
      </div>
    )
    const children = this.props.imageRight ? [content, image] : [image, content];
    const containerClass = this.props.dark ? 'section-dark full-width-background' : '';
    return (
      <div className={containerClass}>
        <div className='section-container'>
          { mobileContent }
          { children }
        </div>
      </div>
    );
  }
}

export default SectionWithImg;